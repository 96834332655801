import type { ReactNode } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack } from '@qasa/qds-ui'
import { Dialog } from '@qasa/app/src/components/dialog'

import { BRAND_CONFIG } from '../../../brands/brand-configurations'
import { AVAILABLE_LOCALES } from '../../../translations/language-loader/language-utils'
import { useCurrentLocale } from '../../../translations/use-current-locale'
import { useChangeLocale } from '../../../translations/use-change-locale'

const LanguageButton = Button

type LanguageSwitchDialogProps = {
  /**
   * The trigger for the language switch.
   * Needs to forward its ref and props to a button element.
   */
  trigger: ReactNode
}

export function LanguageSwitchDialog({ trigger }: LanguageSwitchDialogProps) {
  const [isLanguageDialogOpen, setLanguageDialogOpen] = useState(false)
  const locale = useCurrentLocale()
  const changeLocale = useChangeLocale()
  const { t } = useTranslation('language_switch')

  const currentLanguage = AVAILABLE_LOCALES.find(({ languageCode }) => languageCode === locale)

  if (BRAND_CONFIG.allowedLanguages.length < 2 || !currentLanguage) {
    return null
  }

  return (
    <Dialog isOpen={isLanguageDialogOpen} onOpenChange={setLanguageDialogOpen} trigger={trigger}>
      <Dialog.Body>
        <Stack gap={'6x'} alignItems={'flex-start'}>
          <Dialog.Header>
            <Dialog.Title>{t('title')}</Dialog.Title>
          </Dialog.Header>
          <Stack gap={'4x'} alignItems={'flex-start'}>
            {AVAILABLE_LOCALES.filter(({ languageCode: code }) =>
              BRAND_CONFIG.allowedLanguages.includes(code),
            ).map(({ nativeName: name, languageCode: code }) => (
              <LanguageButton variant="tertiary" key={code} onClick={() => changeLocale(code)}>
                {name}
              </LanguageButton>
            ))}
          </Stack>
        </Stack>
      </Dialog.Body>
    </Dialog>
  )
}
